import React from 'react';

const CardBanner = () => {
  return (
    <div className='card_banner'>
      <div className='col-md-12 p-3'>
        <div className='d-flex justify-content-center'>
          <h1 style={{ color: 'white' }}>Secure Your Card Today*</h1>
        </div>
        <div className='d-flex justify-content-center'>
          <h4 style={{ color: 'white' }}>
            Secure your free <b>BhartPeCard</b> Free Credit Card{' '}
          </h4>
        </div>
        <div className='d-flex justify-content-center'>
          <img
            src='img/visa1.jpeg'
            alt='no image found'
            style={{ boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px' }}
            className='offer_img'
          />
        </div>
      </div>
    </div>
  );
};

export default CardBanner;
